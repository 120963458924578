import React from "react";
import classNames from "classnames";

import styles from "./Typography.module.scss";

const Typography = ({ tagName: El = "p", type = "paragraph", className, children, ...props }) => {
  const cls = classNames(styles[type], className);
  return (
    <El className={cls} {...props}>
      {children}
    </El>
  );
};

export { Typography };
