import Figure from "../common/Figure/Figure";
import Video from "../common/Video/Video";
import Link from "../common/Link/Link";

export const serializers = {
  marks: {
    link: Link
  },
  types: {
    figure: Figure,
    video: Video
  }
};
